@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'GmarketSans';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: /*url('/fonts/GmarketSansTTFMedium.woff2') format('woff2'), */
         url('./fonts/GmarketSansTTFMedium.ttf') format('truetype');
  }
body {
    font-family: "GmarketSans";
}


